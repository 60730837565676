.wrapper .mp-footer {
    width: calc(100% - 40px);
    min-height: 80px;
    padding: 10px 20px;
    background-color: var(--grey-color);
}

.wrapper .mp-footer .mp-footer__logo {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.wrapper .mp-footer .mp-footer__logo span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    max-width: 60px;
    border-radius: 10px;
    background-color: var(--grey-color);
    z-index: 1;
}

.wrapper .mp-footer .mp-footer__logo span img {
    width: 100%;
    max-width: 148px;
}

.wrapper .mp-footer .mp-footer__logo::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: white;
}

.wrapper .mp-footer .mp-footer__info, 
.wrapper .mp-footer .mp-footer__info .mp-footer__links, 
.wrapper .mp-footer .mp-footer__info .mp-footer__web {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: white;
}

.wrapper .mp-footer .mp-footer__info .mp-footer__links .mp-footer__links_site .MuiList-root .MuiListItem-root:first-of-type .MuiListItemText-root .MuiTypography-root::first-letter {
    color: var(--primary-color);
}

.wrapper .mp-footer .mp-footer__info .mp-footer__links .mp-footer__links_contact .MuiList-root .MuiListItem-root:first-of-type .MuiListItemText-root .MuiTypography-root {
    color: var(--primary-color);
}

.wrapper .mp-footer .mp-footer__info .mp-footer__web .mp-footer__web_rrss .MuiList-root, 
.wrapper .mp-footer .mp-footer__info .mp-footer__web_download .MuiList-root {
    padding-bottom: 0;
}

.wrapper .mp-footer .mp-footer__info .mp-footer__web .mp-footer__web_rrss .MuiList-root .MuiListItem-root, 
.wrapper .mp-footer .mp-footer__info .mp-footer__web_download .MuiList-root .MuiListItem-root {
    padding-bottom: 0;
}

.wrapper .mp-footer .mp-footer__info .mp-footer__web .mp-footer__web_rrss-links .MuiList-root, 
.wrapper .mp-footer .mp-footer__info .mp-footer__web_download .mp-footer__web_download-links .MuiList-root {
    display: flex;
    padding: 0;
}

.wrapper .mp-footer .mp-footer__info .mp-footer__web .mp-footer__web_rrss-links .MuiList-root .MuiListItem-root, 
.wrapper .mp-footer .mp-footer__info .mp-footer__web_download .mp-footer__web_download-links .MuiList-root .MuiListItem-root {
    width: auto;
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 10px;
}

.wrapper .mp-footer .mp-footer__info .mp-footer__web .mp-footer__web_rrss-links .MuiList-root .MuiListItem-root:first-of-type, 
.wrapper .mp-footer .mp-footer__info .mp-footer__web_download .mp-footer__web_download-links .MuiList-root .MuiListItem-root:first-of-type {
    padding-left: 16px;
}

.wrapper .mp-footer .mp-footer__info .mp-footer__web .mp-footer__web_rrss-links .MuiList-root .MuiListItem-root .MuiListItemIcon-root, 
.wrapper .mp-footer .mp-footer__info .mp-footer__web_download .mp-footer__web_download-links .MuiList-root .MuiListItem-root .MuiListItemIcon-root {
    min-width: 40px;
}

.wrapper .mp-footer .mp-footer__info .mp-footer__web .mp-footer__web_rrss-links .MuiList-root .MuiListItem-root .MuiListItemIcon-root .MuiSvgIcon-root, 
.wrapper .mp-footer .mp-footer__info .mp-footer__web_download .mp-footer__web_download-links .MuiList-root .MuiListItem-root img  {
    width: 40px;
    height: 40px;
}

.wrapper .mp-footer .mp-footer__info .mp-footer__web_download .mp-footer__web_download-links .MuiList-root .MuiListItem-root:last-of-type img  {
    width: 55px;
    height: 55px;
}

.wrapper .mp-footer .mp-footer__notice p {
    color: #999999;
}

/* -------------- MEDIAQUERIES -------------- */

@media (min-width: 600px) {
    
    .wrapper .mp-footer .mp-footer__info .mp-footer__links, 
    .wrapper .mp-footer .mp-footer__info .mp-footer__web {
        flex-direction: row;
        width: 100%;
    }
    .wrapper .mp-footer .mp-footer__info .mp-footer__links .mp-footer__links_contact .MuiList-root .MuiListItem-root {
        text-align: right;
    }
}

@media (min-width: 960px) {

    .wrapper .mp-footer .mp-footer__info {
        flex-direction: row;
    }

    .wrapper .mp-footer .mp-footer__info .mp-footer__links {
        margin-right: 5%;
    }

    .wrapper .mp-footer .mp-footer__info .mp-footer__web {
        margin-left: 5%;
    }
    
    .wrapper .mp-footer .mp-footer__info .mp-footer__links .mp-footer__links_contact .MuiList-root .MuiListItem-root {
        text-align: left;
    }
    
}

@media (min-width: 1280px) {

    .wrapper .mp-footer .mp-footer__info {
        flex-direction: row;
    }

}

/* -------------- END MEDIAQUERIES -------------- */
