#card_1 .mp-info__card_wrapper,
#card_2 .mp-info__card_wrapper {
    min-height: 600px;
}
#card_2 .mp-info__card-content-title {
    text-align: right;
    width: 100%;
}


.mp-info .mp-info-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 100px auto 0 auto;
    width: 100%;
    max-width: 1280px;
    height: 100%;
}

.cabecera {
    width: 100%;
    height: 400px;
    color: white;
    max-width: 1280px;
    font-size: 2.2rem;
    background-image: url('/images/products/cabecera.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000000;    
}

.cabecera .texto-cabecera1 {    
    position: absolute;
    top: 200px;    
    left: 50%;
    transform: translateX(-50%);
}
.cabecera .texto-cabecera2 {    
    color: var(--primary-color);
    position: absolute;
    top: 270px;    
    left: 50%;
    transform: translateX(-50%);
}

.mp-info__card {
    position: relative;
    flex: 1 1 100%;
    background-color: white;
}

.mp-info__card .mp-info__card_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 283px;
    min-height: 430px;
    overflow: hidden;
}

.mp-info__card .mp-info__card-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    background-color: transparent;
}

.mp-info__card .mp-info__card-content .mp-info__card-content-title {
    font-size: 3rem;
    font-weight: 700;
    margin-top: 10px;
    width: 70%;;
}

.mp-info__card .mp-info__card-content .mp-info__card-content-text {
    font-size: 1rem;    
}

#card_1 .mp-info__card-content-text, 
#card_2 .mp-info__card-content-text {
    width: 250px;
    margin-top: 30px;
    margin-left: 30px;
    padding: 5px;
    background-color: white;
    color: var(--grey-color);
}

#card_2 .mp-info__card-content-text {
    width: 450px;
    margin: 30px 50px auto auto;
    font-size: 1.4rem;
}

#card_1 .mp-info__card-content-highlight-group {
    position: absolute;
    top: 50%;
    left: 10%;
    margin-top: 20px;
    font-size: 1.2rem;
}

#card_1 .mp-info__card-content-highlight-group > div {
    text-align: right;
    width: 220px;
    padding-left: 20px;    
}

#card_1 .mp-info__card-content-highlight-group > div:nth-child(1) {
    margin-left: 40px;
}

#card_1 .mp-info__card-content-highlight-group > div:nth-child(2) {
    margin-left: 20px;
}

#card_1 .mp-info__card-content-highlight {
    position: absolute;
    width: 100%;
    left: 200px;
    bottom: 50px;
    padding: 10px 20px;
    text-align: center;
    font-size: 1rem;
}

#card_2 .mp-info__card-content-highlight {
    position: absolute;
    right: 0;
    bottom: 10%;
    width: 67%;
    color: white;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
}

#card_2 .mp-info__card-content-highlight-ticket {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
    display: inline-flex;
}

#card_2 .mp-info__card-content-highlight-ticket img {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    width: 100px;
    height: auto;
}

#card_2 .mp-info__card-content-highlight-ticket span {
    padding: 40px 40px 40px 140px;
    font-size: 1.6rem;
}

#card_3 .mp-info__card-content-wizard {
    display: flex;
    width: 100%;
}

#card_3 .mp-info__card-content-wizard .mp-info__card-content-wizard-item {
    flex: 1 1 33.33%;
    display: flex;
    padding: 5px;
}

#card_3 .mp-info__card-content-wizard .mp-info__card-content-wizard-item .mp-info__card-content-wizard-item-number {
    position: relative;
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

#card_3 .mp-info__card-content-wizard .mp-info__card-content-wizard-item .mp-info__card-content-wizard-item-number img {
    position: absolute;
    bottom: 0px;
    left: 35%;
    display: inline-block;
    height: 23%;
    transform: translateX(-50%);
}

#card_3 .mp-info__card-content-wizard .mp-info__card-content-wizard-item .mp-info__card-content-wizard-item-step {
    width: 70%;
}

#card_3 .mp-info__card-content-wizard .mp-info__card-content-wizard-item .mp-info__card-content-wizard-item-step .mp-info__card-content-wizard-item-step-image img {
    height: 60px;
}

#card_3 .mp-info__card-content-wizard .mp-info__card-content-wizard-item .mp-info__card-content-wizard-item-step .mp-info__card-content-wizard-item-step-explanation {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

#card_3 .mp-info__card-content-wizard .mp-info__card-content-wizard-item .mp-info__card-content-wizard-item-step .mp-info__card-content-wizard-item-step-explanation p {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 0.75rem;
}

#card_4 .mp-info__card-content-text {
    margin-top: 12px;
}

#card_4 .mp-info__card-content-wallets {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 20px auto 0 auto;
    border-bottom: 1px solid #999999;
}

#card_4 .mp-info__card-content-wallets .mp-info__card-content-wallets-images {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#card_4 .mp-info__card-content-wallets .mp-info__card-content-wallets-images span:first-of-type {
    margin-right: 20px;
}

#card_4 .mp-info__card-content-wallets .mp-info__card-content-wallets-images span img {
    width: 40px;
    height: 40px;
}

#card_4 .mp-info__card-content-wallets p {
    font-size: 0.7rem;
}

#card_4 .mp-info__card-content-highlight-ticket {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
}

#card_4 .mp-info__card-content-highlight-ticket img {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    width: 30%;
}

#card_4 .mp-info__card-content-highlight-ticket span {
    padding: 20px 10px 20px 90px;
    font-size: 1rem;
    color: white;
    white-space: nowrap;
    background-color: #333333;
}

.mp-info__card .mp-info__card-image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mp-info__card .mp-info__card-image img {
    width: 100%;
    height: auto;
    object-fit: cover;    
}

#card_1.mp-info__card .mp-info__card-image {
    right: 0px;
    bottom: -150px;
    width: 100%;
    height: auto;
    max-width: 800px;
}

#card_2.mp-info__card .mp-info__card-image {
    left: 0px;
    bottom: 40px;
    width: 800px;
    height: auto;
}

#card_3.mp-info__card .mp-info__card-image {
    right: 20px;
    bottom: -23px;
    width: 150px;
    height: auto;
}

#card_4 .mp-info__card_wrapper {
    background-color: rgba(255,215,0,0.7);
}

#card_4.mp-info__card .mp-info__card-image {
    left: 0;
    bottom: -15px;
    height: auto;
}

@media (min-width: 600px) {

    .mp-info__card {
        flex: 1 1 50%;
    }

    .mp-info__card .mp-info__card_wrapper {
        min-height: 490px;
    }
    
}

@media (min-width: 960px) {

    .mp-info__card {
        flex: 1 1 25%;
    }

    .mp-info__card .mp-info__card_wrapper {
        min-width: 421px;
    }
    
}

@media (min-width: 1280px) {

    .mp-info__card .mp-info__card_wrapper {
        min-width: 315px;
    }
    
}
