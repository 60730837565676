.mp-advertisement {
    height: 270px;
    max-height: 270px;
    overflow: hidden;
    display: flex;    
    background-color: var(--primary-color);
}

.mp-advertisement-right {
    width: 55%;
    display: flex;
    padding-top: 10px;
}

.mp-advertisement-right-star {
    position: absolute;
    left: -110px;
    margin-top: 10px;
    width: calc(100% + 110px);
    z-index: 1;
    text-align: right;
    padding: 5px;
}


.mp-advertisement-text {
    width: 45%;
    margin-left: 20px;
    background-color: transparent;
    padding-top: 40px;
}

.mp-advertisement-text h1 {
    color: white;
    font-size: 1.5rem;
    margin: 10px 0px;
}
.mp-advertisement-text h3 {
    color: black;
    font-size: 2.5rem;
    margin: 10px 0px;
}
.mp-advertisement-text p {
    color: black;
}

@media (max-width: 850px) {
    
    .mp-advertisement-right {
        display: none;
    }
}
@media (max-width: 599px) {
    
    .mp-advertisement-text h1 {
        font-size: 2rem;
        top: 90px;
    }
    .mp-advertisement-text h3 {
        font-size: 1.5rem;
    }
}
@media (min-width: 600px) {
    
    .mp-advertisement-text h1 {
        font-size: 2.5rem;
        top: 90px;
    }
    .mp-advertisement-text h3 {
        font-size: 1.8rem;
    }
}

@media (min-width: 960px) {

    .mp-advertisement-text-highlight {
        padding: 10px;
        font-size: 1rem;
    }

    .mp-advertisement-text h1 {
        font-size: 3rem;
        top: 90px
    }
    .mp-advertisement-text h3 {
        font-size: 1.8rem;
    }

}

@media (min-width: 1280px) {
        
}

