:root {
    --primary-color: #FFE400;
    --primary-color-dark: #B59A00;
    --primary-color-highlight: #CCAF0C;
    --secondary-color: #ffaeff;
    --secondary-light-color: #FFADFF;
    --tertiary-color: #00FFFF;
    --tertiary-light-color: #ADFFFF;
    --warning-color: #E52420;
    --light-grey-color: #DEDEDE;
    --white-color: #FFFFFF;
    --black-color: #000000;
    --grey-color: #312c2c;
    --concierto-color: #92ffff;
    --box-card: #2b2e2e;
    --backgroundColor-body: rgb(235 239 239);
}

/*
@font-face {
    font-family: "Renogare";
    src: url("/fonts/Renogare-Regular.otf");
}
*/  

@font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("/fonts/Montserrat/Montserrat-Bold.ttf");
}

body {
    margin: 0px;
    padding: 0px;

    background-color: var(--backgroundColor-body);

    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;
}

.text-white {
    color: rgb(255, 255, 255);
}

.background-disco {
    background-image: url("/images/block/disco.jpg");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
    background-color: rgb(0, 0, 0);
}

a {
    color: var(--grey-color);
}

.box-blanco {
    padding: 10px;
    margin: 10px;
    margin-top: 0;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);

}
.box-blanco > h5 {
    color: white;
} 




 /* Login */
.login {
    padding-bottom: 20px;
}

.login__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     
}

.login__header-paragraph {
    font-size: 3rem;
    color: white;
}

.login-steps {
    height: 95vh;
    display: flex;
    align-items: center;    
}

.login-steps__container {
    -color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-steps__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 36%;
}

.login-steps__header-title {
    font-size: 3.5rem;
    margin: 0;
    margin-top: 5%;
    margin-bottom: 0.25rem;
    font-weight: 500;
}

.login-steps__header-paragraph {
   font-size: 1.5rem;
   width: 75%;
   text-align: center;
}

.login-steps__header-highlight {
    color: var(--warning-color);
    font-weight: 700;
}
.login-steps__header-footer {
   font-size: 1.5rem;
   width: 75%;
   text-align: center;
   margin-bottom: 0.25rem;
}


.login-steps__steps {
    height: 33%;
    border-bottom: 1px solid var(--primary-color);
    border-top: 1px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
}

.login-steps__step {
    width: 25%;
    display: flex;
    flex-direction: column;
    height: 60%
}

.login-steps__step-img {
    align-self: center;
}
.login-steps__step-paragraph {
    font-weight: 500;
    text-align: center;
}

.login-steps__footer-paragraph {
    font-size: 1.5rem;
    text-align: center;
}

.login-steps__footer-highlight {
}

.login-footer {
 background-color: var(--primary-color);
 height: 80vh;
}

.MuiContainer-root.login-footer__container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
}

.login-footer__paragraph {
    text-align: center;
}

.login-footer__box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-footer__logo {
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.login-footer__logo-img {
    align-self: center;
}

.login-footer__logo-text {
    font-size: 3rem;
}

.login-footer__wallet {
    align-self: flex-end;
    margin-left: auto;
    margin-right:  auto;
}



/* main */
.main {
    height: 100vh;
}

/* user-list */

.user-list__box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}

.user-list__img {
    max-width: 110;
    max-height: 150px;
    height: auto;
    width: 120px;
    border-radius: 15px;
}


@media (max-width: 1600px) {
    .login-footer__box {
        width: 100%;
        flex-direction: column
    }
    .login-footer__logo {
        margin-left: 0;
    }
}

@media (max-width: 1000px) {
    .login__header-paragraph {
        font-size: 2.5rem;
    }
}

@media (max-width: 900px) {
    .login-steps__steps {
        flex-direction: column;
        border: none;
        height: auto;
    }
    .login-steps__step {
        width: 70%;
    }
    .login-steps {
        height: auto;
    }
    
}

@media (max-width: 800px) {
    .login-steps__header-title {
        font-size: 2.5rem;
        text-align: center;
    }
    .login-footer {
        height: auto;
    }
    .login__header-paragraph {
        text-align: center;
    }

    .login-footer__logo-text {
        text-align: center;
    }

    .user-list__box {
        flex-direction: column;
    }
    .main {
        height: auto;
    }
    .login {
        height: auto;
    }
}