.link-button-icon {
    position: relative;
    display: inline-flex;
    padding: 10px 20px 10px 60px;
    color: #333333;
    white-space: nowrap;
    cursor: pointer;
}

.link-button-icon img {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 40px;
}

.link-button-icon span {
    padding: 0 0 0 5px;
    font-size: 1rem;
    font-weight: 700;
}
