.mp-show-card {
    position: relative;
    max-height: 300px;
    overflow: hidden;
}

.mp-show-card_text {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 40%;
    height: 100%;
    background-color: white;
}

.mp-show-card .mp-show-card_text.big-poster {
    display: flex;
    overflow: hidden;
}

.mp-show-card .top-calification {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 15%;
}

.mp-show-card .top-calification img {
    width: 100%;
    height: auto;
    max-width: 100px;
}

.mp-show-card_text.big-poster .big-poster_belt {
    position: relative;
    display: flex;
    width: 15%;
}

.mp-show-card_text.big-poster .big-poster_belt > div {
    position: absolute;
    top: 4%;
    left: 25%;
    height: 90%;
    font-size: 0.75rem;
    font-weight: 700;
    color: #000000;
    transform: rotate(-180deg);
    writing-mode: vertical-rl;
}

.mp-show-card_text.big-poster .big-poster_info {
    width: calc(65% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 10px;
}

.mp-show-card_text.big-poster .big-poster_info p {
    margin: 0;
}

.mp-show-card_text.big-poster .big-poster_info .title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 2px;
}

.mp-show-card_text.big-poster .big-poster_info .description {
    margin: 10px 0;
    font-size: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mp-show-card_text.big-poster .big-poster_info .big-poster_info-data {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 3px;
}

.mp-show-card_text.big-poster .big-poster_info .big-poster_info-data p {
    font-size: 0.75rem;
    font-weight: 700;
}

.mp-show-card_text.big-poster .big-poster_info-interaction {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 10px;
    padding: 0;
}

.mp-show-card_text.big-poster .big-poster_interaction {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 20%;
}

.mp-show-card_text.big-poster .big-poster_interaction .big-poster_interaction-button {
    position: relative;
    width: 70%;
    height: 100%;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}

.mp-show-card_text.big-poster .big-poster_interaction .big-poster_interaction-button img {
    position: absolute;
    top: 50%;
    right: 40%;
    transform: translateY(-50%);
    width: 15px;
    height: auto;
    background-color: white;
    padding: 8px;
    border-radius: 50%;
}

.mp-show-card .mp-show-card_text-sign {
    position: absolute;
    top: unset;
    right: 0;
    bottom: 0;
    left: unset;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 120px;
    max-width: 373px;
    background-color: white;
    overflow: hidden;
}

.mp-show-card_text-sign .top-calification img {
    max-width: 75px
}

.mp-show-card .lateral {
    position: relative;
    width: 50px;
    height: 100%;
}

.mp-show-card .lateral span {
    position: absolute;
    bottom: 10px;
    left: 0;
    font-family: "Montserrat-Bold";
    font-weight: 700;
    font-size: 1rem;
    transform: rotate(-180deg) translateX(-50%);
    writing-mode: vertical-rl;
}

.mp-show-card .mp-show-card_text-sign .poster-text {
    display: flex;
    width: 100%;
    padding: 10px 0;
}

.mp-show-card .poster-text_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(65% - 20px);
    padding: 10px 0 0 20px;
}

.mp-show-card .poster-text_info p {
    margin: 0;
}

.mp-show-card .poster-text_info .poster-text_info-titles .title {
    font-size: 1.3rem;
    font-weight: 700;
}

.mp-show-card .poster-text_info .poster-text_info-titles .genre {
    font-size: 1rem;
    font-weight: 700;
    text-transform: capitalize;
}

.mp-show-card .poster-text_info .poster-text_info-data {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.mp-show-card .poster-text_info .poster-text_info-data p {
    font-size: 0.7rem;
    margin: 0;
}

.mp-show-card .poster-text_info .poster-text_info-data p span {
    font-size: 0.75rem;
}

.mp-show-card .poster-text_interaction {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: calc(35% - 20px);
    padding: 0 10px;
}

.mp-show-card .poster-text_interaction .poster-text_info-stars {
    position: relative;
    width: 100px;
    height: 22px;
    overflow: hidden;
}

.mp-show-card .poster-text_interaction .poster-text_info-stars img {
    position: absolute;
    left: 0;
    top: 0;
    width: 206px;
    height: auto;
}

.mp-show-card .more-info-btn {
    padding: 17px;
    font-family: "Montserrat-Bold";
    font-size: 0.8rem;
    font-weight: 700;
    color: var(--black-color);
    background-color: var(--primary-color);
    cursor: pointer;
}

.mp-show-card .mp-show-card_image {
    width: 100%;
    max-width: 1280px;
    min-height: 250px;
    margin: 0 auto;
    background-color: #ffffff;
    overflow: hidden;
}

.mp-show-card .mp-show-card_image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.mp-show-card.full-card {
    display: flex;
    min-height: 300px;
}

.mp-show-card.full-card .mp-show-card_image {
    width: 60%;
    margin: 0;
    min-height: unset;
}

.mp-show-card.full-card .mp-show-card_image img {
    width: unset;
    max-width: 100%;
    height: 100%;
}

.mp-show-card.full-card .top-calification img {
    max-width: 76px;
}

.mp-show-card.full-card .mp-show-card_text.big-poster .big-poster_info > div {
    margin-top: 2rem;
}

@media (min-width: 500px) {

    .mp-show-card .more-info-btn {
        padding: 17px;
    }
    
}

@media (min-width: 600px) {

    .mp-show-card.full-card .mp-products-card_text.big-poster .big-poster_belt > div {
        font-size: 0.875rem;
    }
    
    .mp-show-card.full-card .mp-show-card_text.big-poster .big-poster_info > div {
        margin-top: 1rem;
    }
    
}

@media (min-width: 720px) {

    .mp-show-card.full-card .mp-show-card_text.big-poster .big-poster_belt > div {
        font-size: 1.1rem;
        left: 29%;
    }

    .mp-show-card.full-card .big-poster_info .title {
        font-size: 1.1rem;
    }

    .mp-show-card.full-card .big-poster_info .description {
        font-size: 0.6rem;
        white-space: normal;
        max-height: unset;
    }

    .mp-show-card.full-card .big-poster_info .big-poster_info-data p.address {
        font-size: 0.85rem;
    }
}

@media (min-width: 960px) {
    
    .mp-show-card.full-card .mp-show-card_image img {
        width: 100%;
        height: auto;
    }

    .mp-show-card.full-card .top-calification img {
        max-width: 76px;
    }
    
    .mp-show-card.full-card .mp-show-card_text.big-poster .big-poster_info > div {
        margin-top: 0;
    }

    .mp-show-card.full-card .mp-show-card_text.big-poster .big-poster_info {
        width: calc(65% - 40px);
        padding: 10px 20px 0 20px;
    }

    .mp-show-card.full-card .big-poster_info .title {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    .mp-show-card.full-card .big-poster_info .description {
        font-size: 0.7rem;
    }

    .mp-show-card.full-card .big-poster_info .big-poster_info-data {
        margin-top: 10px;
    }

    .mp-show-card.full-card .big-poster_info-interaction {
        margin-top: 0;
    }

    .mp-show-card.full-card .mp-show-card_text.big-poster .big-poster_belt > div {
        font-size: 1.25rem;
    }

    .mp-show-card.full-card .big-poster_interaction .big-poster_interaction-button {
        position: relative;
        width: 70%;
        height: 100%;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .mp-show-card.full-card .big-poster_interaction .big-poster_interaction-button img {
        right: 55%;
        width: 30px;
        padding: 15px;
    }
    
}
