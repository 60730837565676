.mp-products .mp-products-wrapper {
    position: relative;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(12, [col] 1fr);
    margin-top: 10px;
    margin-bottom: 10px;
}

.full-card { grid-column: col / span 12; }
.narrow-left-card { grid-column: col / span 12; }
.large-right-card { grid-column: col / span 12; }
.large-left-card { grid-column: col / span 12; }
.narrow-right-card { grid-column: col / span 12; }

@media (min-width: 960px) {
    .narrow-left-card { grid-column: col / span 5 ; }
    .large-right-card { grid-column: col 6 / span 7 ; }
    .large-left-card { grid-column: col / span 7 ; }
    .narrow-right-card { grid-column: col 8 / span 5; }
}
