@font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("/fonts/Montserrat/Montserrat-Bold.ttf");
}

body { font-size:62.5%; }

.wrapper {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    color: #333333;
    font-size: 0.785rem;
    font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.00938em;
}

.wrapper .mp-main {
    width: 100%;
    max-width: 1280px;
    min-height: calc(100vh - 220px);
    margin: 100px auto 0 auto;
    padding: 0;
    background-color: white;
}

.wrapper p {
    font-size: 0.785rem;
}

.wrapper .primary-color {
    color: var(--primary-color);
}

.wrapper .secondary-color {
    color: var(--secondary-color);
}

.wrapper .secondary-light-color {
    color: var(--secondary-light-color);
}

.wrapper .tertiary-color {
    color: var(--tertiary-color);
}

.wrapper .tertiary-light-color {
    color: var(--tertiary-light-color);
}

.wrapper .light-grey-color {
    color: var(--light-grey-color);
}

.wrapper .white-color {
    color: var(--white-color);
}

.wrapper .black-color {
    color: var(--black-color);
}

.wrapper .primary-bg-color {
    background-color: var(--primary-color);
}

.wrapper .secondary-bg-color {
    background-color: var(--secondary-color);
}

.wrapper .secondary-light-bg-color {
    background-color: var(--secondary-light-color);
}

.wrapper .tertiary-bg-color {
    background-color: var(--tertiary-color);
}

.wrapper .tertiary-light-bg-color {
    background-color: var(--tertiary-light-color);
}

.wrapper .light-grey-bg-color {
    background-color: var(--light-grey-color);
}

.wrapper .white-bg-color {
    background-color: var(--white-color);
}

.wrapper .black-bg-color {
    background-color: var(--black-color);
}

.wrapper .concierto-bg-color {
    background-color: var(--concierto-color);
}

.bg-alpha-4 {
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
}

.bold {
    font-weight: 700;
}

.p-5 {
    padding: 5px;
}

.p-x-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.p-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

@media (min-width: 960px) {
    .wrapper .mp-main {
    }
}
