.wrapper .mp-header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 40px);
    width: 100%;
    max-width: 1280px;
    min-height: 100px;
    -padding: 10px 20px;
    background-color: var(--grey-color);
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);    
}

.wrapper .mp-header .mp-header__button {
    background-color: transparent;
}

.wrapper .mp-header .mp-header__logo {
    display: inline-flex;
    -justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 50px;
    background-color: transparent;
}

.wrapper .mp-header .mp-header__logo img {
    width: 90%;
    max-width: 155px;
}

.wrapper .mp-header .mp-header__navigation {
    position: absolute;
    top: 100px;
    left: -220px;
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 10px;
    padding-top: 20px;
    background-color: var(--grey-color);
    z-index: 3;
    transition: left .5s;
}

.wrapper .mp-header .mp-header__navigation.opened {
    left: 0;
}

.wrapper .mp-header .mp-header__navigation .mp-header__navigation-links, 
.wrapper .mp-header .mp-header__navigation .mp-header__navigation-rrss {
    color: white;
}

.wrapper .mp-header .mp-header__navigation .mp-header__navigation-links .MuiList-root .MuiListItem-gutters {
    white-space: nowrap;
    padding-right: 10px;
}

.wrapper .mp-header .mp-header__navigation .mp-header__navigation-links .MuiList-root .MuiListItem-root:first-of-type .MuiListItemText-root .MuiTypography-root::first-letter {
    color: var(--primary-color);
}

.wrapper .mp-header .mp-header__navigation .mp-header__navigation-rrss .MuiList-root {
    display: flex;
}

.wrapper .mp-header .mp-header__navigation .mp-header__navigation-rrss .MuiList-root .MuiListItem-root.MuiListItem-gutters {
    padding: 4px;
    width: auto;
}

.wrapper .mp-header .mp-header__navigation .mp-header__navigation-rrss .MuiList-root .MuiListItem-root.MuiListItem-gutters .MuiListItemIcon-root {
    min-width: 30px;
}

.wrapper .mp-header .mp-header__tools {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: transparent;
    padding: 20px;
}

.wrapper .mp-header .mp-header__tools .mp-header__tools-login {
    margin: 0 0 10px 0;
}

/* -------------- MEDIAQUERIES -------------- */

@media (min-width: 600px) {

    .wrapper .mp-header .mp-header__logo img {
        width: 100%;
    }
}

@media (min-width: 960px) {

    .wrapper .mp-header {
        justify-content: space-around;
        align-items: stretch;
    }

    .wrapper .mp-header .mp-header__button {
        display: none;
    }

    .wrapper .mp-header .mp-header__logo, 
    .wrapper .mp-header .mp-header__navigation, 
    .wrapper .mp-header .mp-header__tools {
        flex: 1 1 33.33%;
        height: auto;
    }

    .wrapper .mp-header .mp-header__logo {
        position: relative;
    }

    .wrapper .mp-header .mp-header__logo img {
        -position: absolute;
        -top: 50%;
        -left: 50%;
        -transform: translate(-50%, -50%);
    }

    .wrapper .mp-header .mp-header__navigation {
        position: unset;
        z-index: unset;
        width: auto;
    }

    .wrapper .mp-header .mp-header__navigation .mp-header__navigation-links {
        order: 2;
    }

    .wrapper .mp-header .mp-header__navigation .mp-header__navigation-links .MuiList-root, 
    .wrapper .mp-header .mp-header__navigation .mp-header__navigation-rrss .MuiList-root {
        justify-content: flex-end;
        padding-top: 0;
        padding-bottom: 0;

    }

    .wrapper .mp-header .mp-header__navigation .mp-header__navigation-links .MuiList-root {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .wrapper .mp-header .mp-header__navigation .mp-header__navigation-links .MuiList-root .MuiListItem-root {
        text-align: center;
        width: auto;
        padding-bottom: 0;
    }

    .wrapper .mp-header .mp-header__navigation .mp-header__navigation-rrss {
        order: 1;
    }

    .wrapper .mp-header .mp-header__tools::before {
        content: '';
        position: absolute;
        top: 0;
        left: 10px;
        width: 1px;
        height: 100%;
        border-right: 7px dotted var(--primary-color);
    }
    
}

.mp-field {
    position: relative;
    min-width: 100px;
    min-height: 20px;
    padding: 5px 30px 5px 10px;
    border-radius: 20px;
    color: white;
    line-height: 1.5;
    background-color: #333333;
}

.mp-field span {
    font-size: 1rem;
}

.mp-field-icon {
    position: absolute;
    top: 55%;
    right: 5px;
    transform: translateY(-50%);
    line-height: 1;
}


@media (min-width: 1280px) {

    .wrapper .mp-header .mp-header__tools .mp-header__tools-login {
        margin: 0 10px 0 0;
    }

}

/* -------------- END MEDIAQUERIES -------------- */
