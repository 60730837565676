
.show-data-sheet-content {
	display: flex;
	flex-direction: column;
	background-color: white;
	max-height: 90vh;
    overflow: hidden;
    overflow-y: auto;
}

.show-data-sheet-content_image {
	position: relative;
	width: 100%;
	max-height: 300px;
}

.show-data-sheet-content_image-container {
	position: relative;
	max-height: 300px;
    overflow: hidden;
	text-align: center;
}

.show-data-sheet-content_image-container img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

.show-data-sheet-content_info {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: calc(100% - 2rem);
	padding: 1rem;
}

.show-data-sheet-content_info-cover {
	width: 50%;
	margin: 0 auto;
}

.show-data-sheet-content_info-cover img {
	width: 100%;
	height: auto;
	max-width: 250px;
	max-height: 300px;
	object-fit: cover;
}

.show-data-sheet-content_info-data {
	display: flex;
	flex-direction: column;
	margin: 20px 0;
}

.show-data-sheet-content_info-header h1 {
	font-size: 1.7rem;
}

.show-data-sheet-content_info-header h1, 
.show-data-sheet-content_info-header h2 {
	margin-top: 0;
	margin-bottom: 5px;
}

.show-data-sheet-content_info-body {
	width: 100%;
}

.show-data-sheet-content_info-body p {
	margin: 10px 0 5px 0;
	line-height: 1.4;
}

.show-data-sheet-content_info-body h3 {
	margin-top: 0 0 10px 0;
	color: #333333;
	font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.show-data-sheet-content_info-body-interaction {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.show-data-sheet-content_info-body-interaction-rrss {
	max-width: 137px;
	margin-top: 1rem;
}

.show-data-sheet-content_info-body-interaction-rrss img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

.show-data-sheet-content_data-sheet {
	position: relative;
	width: calc(100% - 2rem);
    padding: 1rem;
}

.show-data-sheet-content_data-sheet-header {
	position: absolute;
	top: 0;
	left: 16px;
	font-size: 1.6rem;
	font-weight: 700;
}

.show-data-sheet-content_data-sheet-body {
	border-top: 1px solid var(--primary-color);
	border-right: 1px solid var(--primary-color);
	border-bottom: 1px solid var(--primary-color);
	border-left: 1px solid transparent;
    padding: 1rem;
}

.show-data-sheet-content_data-sheet-body h3 {
    position: relative;
}

.show-data-sheet-content_data-sheet-body h3::before {
    content: '';
	position: absolute;
	bottom: -10px;
	left: 0;
	display: block;
	width: 100%;
	height: 1px;
	background-color: #CDCDCD;
}

.show-data-sheet-content_data-sheet-body h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.show-data-sheet-content_data-sheet-body > div:last-of-type p {
	margin-bottom: 0.5rem;
	font-size: 0.75rem;
}

@media (min-width: 600px) {
	
	.show-data-sheet-content_data-sheet {
		width: calc(100% - 4rem);
		margin-top: 2rem;
		padding: 1rem 2rem;
	}

	.show-data-sheet-content_data-sheet-header {
		left: 32px;
	}

	.show-data-sheet-content_data-sheet-body {
		display: flex;
		padding: 2rem 0 1rem 0;
	}

	.show-data-sheet-content_data-sheet-body > div {
		width: 50%;
	}

	.show-data-sheet-content_data-sheet-body > div:first-of-type {
		padding: 0 1rem 0 0;
	}

	.show-data-sheet-content_data-sheet-body > div:last-of-type {
		padding: 0 0 0 1rem;
	}

}

@media (min-width: 768px) {

	.show-data-sheet-content_info {
		flex-wrap: nowrap;
		width: calc(100% - 4rem);
		padding: 1rem 2rem;
	}

	.show-data-sheet-content_info-cover {
		width: 30%;
		margin: 34px 1rem 0 0;
	}

	.show-data-sheet-content_info-cover img {
		max-height: unset;
	}

	.show-data-sheet-content_info-data {
		width: 70%;
		justify-content: space-between;
		margin: 0;
	}

	.show-data-sheet-content_info-body-interaction {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.show-data-sheet-content_info-body-interaction-rrss {
		margin-top: unset;
	}

}

@media (min-width: 960px) {

	.show-data-sheet-content_info-cover {
		margin-right: 50px;
    	max-width: 250px;
	}

}
