.modal {
    pointer-events: none;
    display: block;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    opacity: 0;
    transition: opacity .5s;
}

.modal.is-open {
    opacity: 1;
    pointer-events: all;
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
	height: 95%;
    padding: 0;
    border: 1px solid #888888;
    border-radius: 4px;
    background-color: #FEFEFE;
}

.modal-close {
    position: absolute;
    top: 15px;
    right: 30px;
	cursor: pointer;
	color: #DEDEDE;
	border-radius: 50%;
	background-color: rgba(0,0,0,0.5);
	font-size: 40px;
	display: inline-block;
	line-height: 0px;
	padding: 15px 4px;
	z-index: 1;
}

.modal-close:hover,
.modal-close:focus {
    color: white;
	background-color: rgba(0,0,0,0.25);
}

.modal-content {
	display: flex;
	flex-direction: column;
	background-color: white;
	max-height: 90vh;
    overflow: hidden;
    overflow-y: auto;
}

@media (min-width: 768px) {

	.modal-container {
		width: 100%;
    	max-width: 700px;
	}

}

@media (min-width: 960px) {

	.modal-container {
    	max-width: 768px;
	}

}
