.rating {
    display: inline-flex;
    margin-bottom: 10px;
}

.rating.completed .star-item .star polygon {
    fill: var(--primary-color) !important;
}

.rating.not-enabled {
    pointer-events: none;
}

.star-wrapper {
    display: inline-flex;
    width: 20px;
    height: 22px;
    cursor: pointer;
}

.star-item {
    transform: scale(0.85);
}

.star-item .star polygon {
    fill: #d8d8d8;
}

.rating .star-item.filled .star polygon {
    fill: var(--primary-color) !important;
}
