.carousel-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    max-height: 500px;
    padding: 20px 0;
}

.carousel-wrapper:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    opacity: 0.6;
    background-image: url('/images/products/cabecera.png');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    background-color: #000000;
}

.carousel-wrapper * {
    box-sizing: border-box;
}

.carousel-wrapper .slideshow-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    /* max-width: 500px; */
    max-width: 90%;
    max-height: 500px;
    margin: 0 auto;
    padding: 0;
    box-shadow: 0px 10px 25px 0px rgba(0,0,0,0.5);
}

.carousel-wrapper .slideshow-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    max-height: 500px;
    margin: auto;
}

.carousel-wrapper .arrows-wrapper {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.carousel-wrapper .prev, 
.carousel-wrapper .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.3s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    background-color: rgba(0,0,0,0.5);
}


.carousel-wrapper .next {
    right: 0;
    border-radius: 3px 0 0 3px;
}


.carousel-wrapper .prev:hover, 
.carousel-wrapper .next:hover {
    background-color: rgba(0,0,0,0.7);
}


.carousel-wrapper .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
}


.carousel-wrapper .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}


.carousel-wrapper .dots-wrapper {
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 3px;
    transform: translateX(-50%);
    z-index: 1;
}

.carousel-wrapper .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.carousel-wrapper .slideshow-switch {
    text-align: center;
    position: absolute;
    right: 10px;
    bottom: 5px;
    /* transform: translateX(-50%); */
    z-index: 1;
}

.carousel-wrapper .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

.carousel-wrapper .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.carousel-wrapper .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.carousel-wrapper .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.carousel-wrapper input:checked + .slider {
    background-color: var(--primary-color-highlight);
}

.carousel-wrapper input:focus + .slider {
    box-shadow: 0 0 1px var(--primary-color-highlight);
}

.carousel-wrapper input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

.carousel-wrapper .slider.round {
    border-radius: 34px;
}

.carousel-wrapper .slider.round:before {
    border-radius: 50%;
}

.carousel-wrapper .active, 
.carousel-wrapper .dot:hover {
    background-color: var(--primary-color-highlight);
}


.carousel-wrapper .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

.slides.none {
    display: none;
}

.slides.block {
    display: block;
}

@-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
}

@media (min-width: 960px) {

    .carousel-wrapper .slideshow-wrapper {
        max-width: 900px;
    }
    
}
