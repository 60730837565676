.strip-wrapper {

    --width-internal: 90%;

    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    /* max-height: 500px; */
    padding: 0;
}

.strip {
    position: relative;
    width: 100%;
    /* min-height: 300px; */
}

.strip-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: var(--width-internal);
    margin: 2rem auto;
    background-color: var(--white-color);
}

.strip-header h1 {
    margin: 0 5rem 0 0;
    font-size: 2rem;
    white-space: nowrap;
}

.strip-info {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
}

.strip-info_cards {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: var(--width-internal);
    height: 100%;
    margin: 0 auto;
    padding: 30px 0;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 1rem; */
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
}

.strip-info_card-item {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 30px;
}

.strip-info_card-item:last-of-type {
    margin-bottom: 0;
}

/* 

.strip-info_card-item {
    display: flex;
    justify-content: flex-start;
    width: 50%;
} */

.strip-info_card-item-number {
    position: relative;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.strip-info_card-item-number img {
    height: 100%;
    max-height: 70px;
}

.strip-info_card-item-number:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 1px;
    height: 50%;
    border-left: 7px dotted white;
}

.strip-info_card-item-data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 80%;
    
}

.strip-info_card-item-data img {
    width: 100%;
    max-width: 120px;
}

.strip-info_card-item-data-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-left: 20px;
}

.strip-info_card-item-data-info-rating {
    position: relative;
    display: flex;
    width: 85px;
    height: 20px;
    margin-top: 20px;
}

.strip-info_card-item-data-info-rating img {
    max-width: 20px;
    height: auto;
    filter: brightness(5);
}

.strip-info_card-item-data-info span {
    color: var(--white-color);
}

@media (min-width: 600px) {

    .strip-info_cards {
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    .strip-info_card-item {
        width: 50%;
    }

    .strip-info_card-item:nth-child(3) {
        margin-bottom: 0;
    }

    .strip-info_card-item-number img {
        max-height: 55px;
    }

    .strip-info_card-item-data > img {
        max-width: 100px;
    }

    .strip-info_card-item-data-info {
        padding-left: 10px;
    }

}

@media (min-width: 960px) {

    .strip-header {
        flex-direction: row;
        align-items: center;
    }

    .strip-header h1 {
        font-size: 3rem;
    }

    .strip-info_cards {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .strip-info_card-item {
        width: 25%;
        margin-bottom: 0;
        margin-right: 20px;
    }

    .strip-info_card-item:last-of-type {
        margin-right: 0;
    }

    .strip-info_card-item-number {
        width: 30%;
    }

    .strip-info_card-item-number img {
        max-height: 100px;
    }

    .strip-info_card-item-number:before {
        height: 65%;
    }

    .strip-info_card-item-data {
        flex-direction: column;
        align-items: flex-start;
        width: 70%;
        margin-left: 10px;
    }

    .strip-info_card-item-data > img {
        max-width: 141px;
    }

    .strip-info_card-item-data-info {
        padding-left: 0;
    }

}
